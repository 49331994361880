import { createReducer, on } from "@ngrx/store";
import * as adminActions from "../actions/admin.event.actions";
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Event } from "src/app/core/models/event";

export const adapter: EntityAdapter<Event> = createEntityAdapter<Event>({
    sortComparer: false
});

export interface AdminEventsState extends EntityState<Event> {
    loading: boolean;
    loaded: boolean;
}

export const initialState: AdminEventsState = {
    ids: [],
    entities: {},
    loading: true,
    loaded: false,
};

export const adminEventsReducer = createReducer(
    initialState,
    on(adminActions.loadAdminEventsStart, (state) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        }
    }),
    on(adminActions.loadAdminEventsSuccess, (state, { events }) => {
        return adapter.setAll(events, {
            ...state,
            loaded: true,
            loading: false,
        });
    }),
);

export const getAdminEventsState = (state: AdminEventsState) => state;
export const getAdminEventsLoading = (state: AdminEventsState) => state.loading;
export const {
    selectIds: selectAdminEventIds,
    selectEntities: selectAdminEventEntities,
    selectAll: selectAllAdminEvents,
    selectTotal: adminEventsCount,
  } = adapter.getSelectors();