import { createReducer, on } from "@ngrx/store";
import * as venueActions from "../actions/venue.actions";
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { Venue } from "src/app/core/models/venue";
import { Event } from "src/app/core/models/event";

export interface VenueState extends EntityState<Venue> { 
    ids: string[];
    entities: { [id: string ]: Venue };
    loading: boolean;
    loaded: boolean | null;
    lastUpdated: Date | null;
}

export const adapter: EntityAdapter<Venue> = createEntityAdapter<Venue>({ sortComparer: false });

export const initialState: VenueState = {
    ids: [],
    entities: {},
    loading: true,
    loaded: false,
    lastUpdated: null
};

export const venueReducer = createReducer(
    initialState,
    on(venueActions.loadVenuesStart, (state) => {
        return {
            ...state,
            loading: true,
            loaded: false,
        }
    }),
    on(venueActions.loadVenuesComplete, (state, { venues }) => {
        const newVenues: Venue[] = [];
        venues.forEach((venue: Venue) => {
            const newVenue = {
                ...venue,
                num_events: 0
            };
            newVenues.push(newVenue);
        })
      const update = adapter.setAll(newVenues, state);
      return {
        ...update,
        loaded: true,
        loading: false,
        lastUpdated: new Date(),
      };
    }),
    on(venueActions.loadVenueEventsComplete, (state, { events }) => {
        events.forEach((event: Event) => {     
            if(!state.entities[event.venue.id]) {
                return;
            }       
            if(state.entities[event.venue.id].events) {
                state.entities[event.venue.id].num_events++;
                state.entities[event.venue.id].events?.push(event)
            } else {
                state.entities[event.venue.id].num_events = 1;
                state.entities[event.venue.id].events = [event];
            }
        });
        
        return {
            ...state,
            entities: {
                ...state.entities
            }
        }
    })
);

export const getVenueState = (state: VenueState) => state;
export const getVenuesLoading = (state: VenueState) => state.loading;
export const {
    selectIds: selectVenueIds,
    selectEntities: selectVenueEntities,
    selectAll: selectAllVenues,
    selectTotal: venuesCount,
  } = adapter.getSelectors();