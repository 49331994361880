export const environment = {
  production: true,
  appVersion: require('../../package.json').version,
  api_keys: {
    google_maps: 'AIzaSyBzXrdEJ6iu5jUPuyVBbkhI2DCIbl2Crds',
    geocoding: 'AIzaSyC8k5OKcs1m02FPFGzOfQs7Zqpj4jZVeLA',
  },
  title: 'The Comedy Stand - Best Live Comedy in Halifax, NS',
  stripe: "pk_live_51NBML4AAx7YpYnj0iQqXwMaXlGmingrLxjiN6aFvTRDBtc3PDAqSobwGoepyc6dKKuks9QgFglh9ItbhzSSsph2j00wAoha9ZD",
  firebase_url: 'https://console.firebase.google.com/u/0/project/comedy-stand/firestore/databases/-default-/data',
  firebase_config: {
    apiKey: "AIzaSyD1tRyHueW0JHy-yYi_pIiOcQ-t5d5P9oI",
    authDomain: "comedy-stand.firebaseapp.com",
    projectId: "comedy-stand",
    storageBucket: "comedy-stand.appspot.com",
    messagingSenderId: "325405971060",
    appId: "1:325405971060:web:460cdd53774137f3f5f721",
    measurementId: "G-GJ48ZY7YMD"
  },
  localStorageKeys: {
    last_order_date: "comedystand-lastOrder",
    last_order_id: "comedystand-lastOrderId",
  }
};
