import * as fromUser from "./user.reducers";
import * as fromTickets from "./ticket.reducers";
import * as fromEvents from "./event.reducers";
import * as fromBlog from "./blog.reducers";
import * as fromCart from "./cart.reducers";
import * as fromVenues from "./venue.reducers";
import * as fromSettings from "./settings.reducers";
import * as fromCards from "./card.reducers";
import * as fromGigSheets from "./gigsheet.reducers";
import * as fromAdminEvents from "./admin.event.reducers";
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

export interface GlobalAppState {
    user: fromUser.UserState,
    tickets: fromTickets.TicketState,
    events: fromEvents.EventState,
    venues: fromVenues.VenueState,
    blog: fromBlog.BlogState,
    cart: fromCart.CartState,
    settings: fromSettings.SettingsState,
    cards: fromCards.CardState,
    gigsheets: fromGigSheets.GigSheetState,
    admin_events: fromAdminEvents.AdminEventsState
}

export const reducers: ActionReducerMap<GlobalAppState> = {
  user: fromUser.userReducer,
  tickets: fromTickets.ticketReducer,
  events: fromEvents.eventsReducer,
  venues: fromVenues.venueReducer,
  blog: fromBlog.blogReducer,
  cart: fromCart.cartReducer,
  settings: fromSettings.settingsReducer,
  cards: fromCards.cartReducer,
  gigsheets: fromGigSheets.gigSheetsReducer,
  admin_events: fromAdminEvents.adminEventsReducer
}

export const getUserState = createFeatureSelector<
  fromUser.UserState
>('user');

export const getTicketState = createFeatureSelector<
  fromTickets.TicketState
>('tickets');

export const getEventsState = createFeatureSelector<
  fromEvents.EventState
>('events');

export const getBlogState = createFeatureSelector<
  fromBlog.BlogState
>('blog');

export const getCartState = createFeatureSelector<
  fromCart.CartState
>('cart');

export const getVenueState = createFeatureSelector<
  fromVenues.VenueState
>('venues');

export const getSettingsState = createFeatureSelector<
  fromSettings.SettingsState
>('settings');

export const getGigSheetState = createFeatureSelector<
  fromGigSheets.GigSheetState
>('gigsheets');

export const getAdminEventsState = createFeatureSelector<
  fromAdminEvents.AdminEventsState
>('admin_events');