import { createSelector  } from '@ngrx/store';
import * as fromRoot from '../reducers';
import * as fromVenues from '../reducers/venue.reducers';
import { Venue } from 'src/app/core/models/venue';

export const selectRootVenueState = (state: fromRoot.GlobalAppState) => state.venues;
export const selectAllVenues = createSelector(selectRootVenueState, fromVenues.selectAllVenues);
export const selectVenuesLoading = createSelector(selectRootVenueState, fromVenues.getVenuesLoading);
export const selectSingleVenue = (slug: string) => createSelector(selectAllVenues, (allVenues) => {
    if(allVenues) {
        return allVenues.filter(venue => {
            return venue.slug === slug;
        })
    } else {
        return [];
    }
});

export const selectVenuesByEvents = createSelector(selectAllVenues, (venues: Venue[]) => {
    const sortedVenues = venues.sort((a, b) => {
        if (a.num_events > 0 && b.num_events === 0) {
          return -1;
        } else if (a.num_events === 0 && b.num_events > 0) {
          return 1;
        } else {
          return 0;
        }
    });

    return sortedVenues;
});